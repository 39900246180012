$basefont: "Lato", sans-serif;
$poppinsfont: "Poppins", "Lato", sans-serif;
$robotofont : "Roboto",
"Poppins",
"Lato",
sans-serif;

$s3 : "https://d2w7xb6113lp9k.cloudfront.net/";

$baseclr: #00D779;
$baseclr2: #00BE6B;
$secondaryclr: #0EF190;
$white: white;
$black: black;
$black24: #242424;
$whiteE8: #E8E8E8;
$black1A: #1A1A22;
$black011: #000011;
$whiteCA: #CACACA;
$dropdownclr: #0A1507;
$whiteC0: #C0C0C0;
$color74: #FD7474;
$colorF2: #E4FFF2;
$color79: #797979;

%border_gradient {
    background-color: rgba($black, 0.5);
    border-radius: 2rem;
    position: relative;
    backdrop-filter: blur(2rem);

    &::before {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        border: 1px solid transparent;
        background: linear-gradient(222.84deg, #FFFFFF 20%, rgba(255, 255, 255, 0) 90%) border-box;
        -webkit-mask: linear-gradient(#000000 0 0) padding-box, linear-gradient(#000000 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

%shadow {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
}