@import "../../../../assets/theme/_var.scss";

.leading_sec {
    padding: 15.4rem 0 11.6rem;
    position: relative;
    z-index: 1;

    // overflow-x: clip;
    @media (max-width: 1399px) {
        padding: 10rem 0;
    }

    @media (max-width: 991px) {
        padding: 6rem 0;
    }

    &::after,
    &::before {
        @extend %shadow;
    }

    &::before {
        width: 192rem;
        height: 287rem;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url($s3 + "s3___lendingBg.png") center/cover no-repeat;
        pointer-events: none;

        @media (max-width: 1199px) {
            content: unset;
        }
    }

    &::after {
        width: 106rem;
        height: 106rem;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -4;
        background: url($s3 + "s3___lendingStarsBg.png") center/cover no-repeat;

        @media (max-width: 1599px) {
            width: 60rem;
            height: 60rem;
        }
    }

    .leading_left {
        @media (max-width: 767px) {
            text-align: center;
        }

        .heading {
            p {
                max-width: 75rem;
                margin: 2.6rem 0;
            }
        }

        ul {
            @media (max-width: 767px) {
                max-width: 27rem;
                margin: 0 auto;
                text-align: left;
            }

            li {
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 2.7rem;
                color: $whiteCA;
                display: flex;
                align-items: center;

                @media (max-width: 1599px) {
                    font-size: 1.6rem;
                    line-height: 1.4;
                }

                @media (max-width: 1399px) {
                    font-size: 1.4rem;
                }

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                svg {
                    flex-shrink: 0;
                    margin-right: 1.4rem;
                }
            }
        }
    }

    .leading_img {
        text-align: end;


        @media (max-width: 767px) {
            margin-top: 2rem;
            text-align: center;
        }

        img {
            @media (max-width: 1599px) {
                width: 50rem;
            }

            @media (max-width: 1599px) {
                width: 40rem;
            }

            @media (max-width: 991px) {
                width: 35rem;
            }
        }
    }
}