@import '../../../../../assets/theme/_var.scss';

.property_card_col {
    padding: 1rem;

    .property_card {
        width: 100%;
        font-family: $robotofont;

        .property_img {
            position: relative;
            z-index: 1;

            img {
                border-radius: 2rem 2rem 0 0;
                width: 100%;
                height: 22rem;
                object-fit: cover;
                object-position: center;

                @media (max-width:991px) {
                    height: 20rem;
                }

                @media (max-width:575px) {
                    height: 18rem;
                }

                @media (max-width:479px) {
                    height: 16rem;
                }
            }

            .favourite {
                top: 1.5rem;
                right: 1.5rem;
                position: absolute;
                height: 4.4rem;
                width: 4.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: .1rem solid rgba($color74, .3);
                background-color: rgba($white, .1);
                border-radius: 1rem;
                backdrop-filter: blur(.8rem);

                @media (max-width: 767px) {
                    width: 3.4rem;
                    height: 3.4rem;

                    svg {
                        width: 1.6rem;
                        height: auto;
                    }
                }

                &.active {
                    svg {
                        path {
                            fill: $baseclr;
                            stroke: $baseclr;
                        }
                    }
                }

                .star {
                    position: absolute;
                }

                svg {
                    z-index: 199;

                    path {
                        stroke: $white;
                        transition: 300ms ease-in-out;
                    }
                }
            }
        }

        .property_card_in {
            @extend %border_gradient;
            margin-top: -.1rem;
            padding: 2.2rem 2.6rem 2rem 2.6rem;
            border-radius: 0 0 2rem 2rem;

            @media (max-width:1539px) {
                padding: 1.6rem;
            }

            .property_header {
                padding-bottom: 1.2rem;

                @media (max-width:1599px) {
                    padding-bottom: 1rem;
                }

                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width:767px) {
                        flex-direction: column;
                        align-items: normal;
                    }

                    h3 {
                        font-size: 2rem;
                        font-weight: 500;
                        line-height: 2.4rem;
                        position: relative;
                        max-width: max-content;
                        display: flex;
                        align-items: center;

                        @media (max-width:1599px) {
                            font-size: 1.8rem;
                            line-height: 2rem;
                        }

                        &::after {
                            content: '';
                            height: .8rem;
                            width: .8rem;
                            background: $baseclr;
                            border-radius: 50%;
                            display: inline-block;
                            margin-left: .5rem;
                        }
                    }

                    h6 {
                        font-family: $robotofont;
                        padding: .5rem 0.7rem;
                        color: $baseclr;
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 2rem;
                        text-align: center;
                        border-radius: .4rem;
                        background: rgba($colorF2, .1);

                        @media (max-width:1599px) {
                            padding: .5rem 1rem;
                        }

                        @media (max-width:767px) {
                            margin-top: 1rem;
                            font-size: 1.2rem;
                        }
                    }
                }

                .subtype {
                    font-size: 1.2rem;
                    margin-top: 0.4rem;
                    margin-left: auto;
                    display: block;
                    width: fit-content;
                }

                p {
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 2rem;
                    color: $whiteCA;
                    text-align: start;
                    margin-top: 1.1rem;

                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    @media (max-width:1599px) {
                        font-size: 1.3rem;
                        line-height: 1.6rem;
                    }
                }
            }

            .annual_values {
                border-top: .1rem solid rgba($color79, .2);
                margin-bottom: 1.6rem;

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        width: 50%;

                        h4 {
                            font-size: 1.4rem;
                            font-weight: 400;
                            line-height: 2rem;
                            color: $whiteCA;
                            margin-bottom: .7rem;
                            white-space: nowrap;

                            @media (max-width:1599px) {
                                font-size: 1.2rem;
                                line-height: 1.6rem;
                            }
                        }

                        p {
                            font-size: 1.8rem;
                            font-weight: 600;
                            line-height: 2.4rem;
                            margin-top: 0;
                            text-align: start;
                            color: $white;

                            @media (max-width:1599px) {
                                font-size: 1.6rem;
                                line-height: 1.8rem;
                            }

                            @media (max-width:575px) {
                                font-size: 1.4rem;
                                line-height: 1.6rem;
                            }
                        }

                        >div {
                            >div {
                                padding-bottom: 0.75rem;
                                padding-top: 0.75rem;
                            }
                        }

                        &:first-child,
                        &:nth-child(2) {
                            >div {
                                >div {
                                    border-bottom: .1rem solid rgba($color79, .2);
                                }
                            }
                        }

                        &:nth-child(odd) {
                            >div {
                                padding-right: 0.75rem;
                                margin-right: 0.75rem;

                                >div {
                                    >div {
                                        height: 100%;
                                        position: relative;

                                        &::after {
                                            content: "";
                                            width: 0.1rem;
                                            height: 100%;
                                            background-color: rgba($color79, .2);
                                            position: absolute;
                                            top: 0;
                                            right: -0.75rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .progress_bar {
                display: flex;
                align-items: center;

                .progress_bar_in {
                    height: 1rem;
                    width: 100%;
                    background: $color79;
                    border-radius: 1.1rem;
                    margin-right: .8rem;
                    overflow: hidden;

                    >div {
                        height: 1rem;
                        background: $baseclr;
                        border-radius: 1.1rem;
                        transform-origin: left;
                    }
                }

                label {
                    font-size: 1.3rem;
                    font-weight: 400;
                    line-height: 2rem;
                    color: $whiteCA;
                    white-space: nowrap;
                }
            }

            .card_action {
                margin-top: 1.6rem;

                @media (max-width:1599px) {
                    margin-top: 1.2rem;
                }

                >div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    h5 {
                        font-size: 1.8rem;
                        font-weight: 600;
                        line-height: 2.4rem;

                        @media (max-width:1599px) {
                            font-size: 1.6rem;
                            line-height: 2rem;
                        }

                        @media (max-width:575px) {
                            font-size: 1.4rem;
                            line-height: 1.6rem;
                        }
                    }
                }

                .buy_btn {
                    margin-top: 2.1rem;
                    height: 5rem;
                    font-weight: 600;
                    font-family: $robotofont;

                    @media (max-width:575px) {
                        margin-top: 1.6rem;
                        height: 4rem;
                    }
                }
            }
        }
    }
}