@import "../../../../assets/theme/_var.scss";

.custom_dropdown {
    will-change: transform, opacity, visibility;
    transform: translateY(2rem);
    opacity: 0;
    transition: 300ms ease-in-out;
    visibility: hidden;
    position: absolute;
    top: 7.8rem;
    right: 0;
    padding: 4rem;
    background: $dropdownclr;
    border-radius: 2rem;
    width: fit-content;

    @media (max-width: 1599px) {
        top: 6rem;
        padding: 2.4rem;
    }

    @media (max-width: 1399px) {
        top: 4.9rem;
        padding: 2rem;
    }

    @media (max-width: 767px) {
        position: static;
        // display: none;
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
    }

    @media (max-width: 575px) {
        padding: 1.4rem;
    }

    &::before {
        content: "";
        width: 100%;
        height: 4rem;
        background-color: transparent;
        position: absolute;
        bottom: 100%;
        left: 0;

        @media (max-width: 1599px) {
            height: 2.4rem;
        }

        @media (max-width: 1399px) {
            height: 2rem;
        }

        @media (max-width: 767px) {
            content: unset;
        }
    }

    .dropdown_in {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -4rem;
        margin-right: -3.3rem;
        width: 100%;

        @media (max-width: 1599px) {
            margin-bottom: -2rem;
            margin-right: -1rem;
        }


        .dropdown_list_item {
            width: 25%;
            padding-right: 3.3rem;
            padding-bottom: 4rem;

            @media (max-width: 1599px) {
                padding-right: 1rem;
                padding-bottom: 2rem;
            }

            @media (max-width: 767px) {
                width: 100%;
            }

            >a {
                display: flex;
                align-items: flex-start;
                color: $white;

                >img {
                    width: 4rem;
                    height: 4rem;
                    object-fit: contain;
                    object-position: center;
                    flex-shrink: 0;
                    margin-right: 1.3rem;

                    @media (max-width: 1599px) {
                        width: 3.4rem;
                        height: 3.4rem;
                        margin-right: 1rem;
                    }

                    @media (max-width: 1199px) {
                        width: 2.8rem;
                        height: 2.8rem;
                    }
                }

                .dropdown_list_item_content {
                    h3 {
                        font-family: $robotofont;
                        font-size: 1.8rem;
                        font-weight: 500;
                        line-height: 2.7rem;

                        @media (max-width: 1599px) {
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                        }

                        @media (max-width: 1399px) {
                            font-size: 1.4rem;
                            line-height: 2.2rem;
                        }

                        @media (max-width: 1199px) {
                            font-size: 1.3rem;
                            line-height: 2rem;
                        }

                        span {
                            font-size: 1.4rem;

                            @media (max-width: 1599px) {
                                font-size: 1.4rem;
                            }

                            @media (max-width: 1399px) {
                                font-size: 1.2rem;
                            }

                            @media (max-width: 1199px) {
                                font-size: 1.1rem;
                            }
                        }
                    }

                    >p {
                        font-family: $robotofont;
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 2rem;
                        color: $whiteC0;
                        margin-top: 0.4rem;

                        @media (max-width: 1599px) {
                            font-size: 1.3rem;
                            line-height: 1.8rem;
                        }

                        @media (max-width: 1399px) {
                            font-size: 1.2rem;
                        }
                    }

                    .dropdown_list_item_list {
                        margin-top: 0.2rem;

                        p {
                            font-family: $robotofont;
                            font-size: 1.4rem;
                            font-weight: 400;
                            line-height: 2rem;
                            color: $white;
                            // display: inline-block;
                            margin-right: 1rem;
                            transition: 300ms ease-in-out ;

                            &:hover {
                                color: $baseclr;
                            }

                            @media (max-width: 1599px) {
                                font-size: 1.3rem;
                                line-height: 1.8rem;
                            }

                            @media (max-width: 1399px) {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}