@import "../../../../assets/theme/_var.scss";

.wealth_sec {
    position: relative;
    z-index: 1;

    // overflow-x: clip;
    &::before {
        content: "";
        width: 162.7rem;
        height: 272.9rem;
        position: absolute;
        opacity: 0.8;
        top: -56rem;
        left: 0;
        background: url($s3 + "s3___wealth-left-shadow-new.png") center/contain no-repeat;
        pointer-events: none;
        z-index: -1;

        @media (max-width: 1599px) {
            top: -40rem;
            width: 100rem;
            height: 200rem;
        }
    }

    &::after {
        content: "";
        width: 182.8rem;
        height: 323.4rem;
        position: absolute;
        opacity: 0.8;
        top: -92rem;
        right: 0;
        background: url($s3 + "s3___wealth-right-shadow.png") center/contain no-repeat;
        pointer-events: none;
        z-index: -1;

        @media (max-width: 1599px) {
            width: 100rem;
            height: 100rem;
            background-position: right;
            top: -20rem;
        }
    }

    .heading {
        text-align: center;
        margin-bottom: 7.8rem;

        @media (max-width: 1799px) {
            margin-bottom: 5rem;
        }

        @media (max-width: 1399px) {
            margin-bottom: 3rem;
        }

        p {
            margin: 1.4rem auto 0;
            max-width: 82.2rem;

            @media (max-width: 1599px) {
                max-width: 74rem;
                margin: 1rem auto 0;
            }

            @media (max-width: 1399px) {
                margin: 0.8rem auto 0;
                max-width: 65rem;
            }
        }
    }

    .container {
        @media (min-width: 1479px) {
            max-width: 146rem;
        }
    }

    .row {
        margin: 0 -0.8rem;

        >div {
            padding: 0 0.8rem;
        }

        @media (max-width: 767px) {
            margin: -0.4rem;

            >div {
                padding: 0.4rem;
            }
        }
    }

    .wealth_card {
        @extend %border_gradient;
        backdrop-filter: blur(0.8rem);
        padding: 5rem 2.6rem 5rem 2.2rem;
        height: 100%;

        &:hover {
            >img {
                transform: translateY(-1rem);
            }

            &::before {
                background: linear-gradient(222.84deg, $baseclr 15%, rgba(255, 255, 255, 0) 110%) border-box;
            }

            h3 {
                color: $baseclr;
            }
        }

        &::before {
            background: linear-gradient(222.84deg, rgba($white , 0.6) 20%, rgba(255, 255, 255, 0) 90%) border-box;
        }

        @media (max-width: 1599px) {
            padding: 4rem 2.2rem 4rem 2rem;
        }

        @media (max-width: 1399px) {
            padding: 2.4rem 1.8rem;
        }

        @media (max-width: 1199px) {
            padding: 2rem 1.4rem;
        }

        @media (max-width: 575px) {
            text-align: center;
        }

        img {
            width: 5.2rem;
            height: 5.2rem;
            margin-bottom: 5rem;
            transition: 300ms ease-in-out;

            @media (max-width: 1599px) {
                width: 4.8rem;
                height: 4.8rem;
                margin-bottom: 4rem;
            }

            @media (max-width: 1399px) {
                width: 4.4rem;
                height: 4.4rem;
                margin-bottom: 3rem;
            }

            @media (max-width: 1199px) {
                width: 4rem;
                height: 4rem;
                margin-bottom: 2.4rem;
            }
        }

        h3 {
            font-size: 2rem;
            font-weight: 400;
            line-height: 2.2rem;
            letter-spacing: -0.04em;
            transition: 300ms ease-in-out;

            @media (max-width: 1599px) {
                font-size: 1.8rem;
                line-height: 1.4;
            }

            @media (max-width: 1399px) {
                font-size: 1.6rem;
            }

            @media (max-width: 1199px) {
                font-size: 1.4rem;
            }
        }
    }
}