@import "../../../../assets/theme/_var.scss";

.grow_sec {
    padding: 0 0 12.2rem;
    position: relative;

    &::before {
        content: "";
        width: 173.5rem;
        height: 273rem;
        position: absolute;
        top: -90rem;
        right: 0;
        z-index: 1;
        background: url($s3  + "s3___grow-right-shadow-new.png") center/contain no-repeat;
        pointer-events: none;

        @media (max-width: 1599px) {
            width: 100rem;
            height: 200rem;
            top: -70rem;
        }
    }

    @media (max-width: 1799px) {
        padding: 0 0 12.6rem;
    }

    @media (max-width: 991px) {
        padding: 0 0 6rem;
    }

    .heading {
        margin-bottom: 5.8rem;
        text-align: center;

        @media (max-width: 1799px) {
            margin-bottom: 5rem;
        }

        @media (max-width: 991px) {
            margin-bottom: 3rem;
        }

        p {
            max-width: 82.2rem;
            margin: 2.4rem auto 0;

            @media (max-width: 1599px) {
                max-width: 74rem;
                margin: 1.6rem auto 0;
            }

            @media (max-width: 1399px) {
                margin: 1rem auto 0;
                max-width: 65rem;
            }
        }
    }

    .grow_cards {
        display: flex;
        flex-wrap: wrap;
        margin: -1.1rem;
        z-index: 3;
        position: relative;

        >div {
            padding: 1.1rem;
        }

        @media (max-width: 1399px) {
            margin: -0.55rem;

            >div {
                padding: 0.55rem;
            }
        }

        @media (max-width: 419px) {
            margin: -0.2rem;

            >div {
                padding: 0.2rem;
            }
        }

        .grow_card_sm {
            width: 28.85%;

            @media (max-width: 665px) {
                width: 50%;

                &.diverse_sm {
                    order: 1;
                }
            }

            @media (max-width: 399px) {
                width: 100%;
            }

            @media (min-width: 666px) {
                &.appreciation_sm {
                    width: 30.45%;
                }

                &.diverse_sm {
                    width: 27.25%;
                }
            }
        }

        .grow_card_lg {
            width: 42.3%;

            @media (max-width: 665px) {
                width: 50%;

                &.earn_lg {
                    order: 1;
                }
            }

            @media (max-width: 399px) {
                width: 100%;
            }
        }

        .grow_card {
            @extend %border_gradient;
            height: 100%;
            overflow: hidden;
            z-index: 1;

            &:hover {
                &::before {
                    background: linear-gradient(252.84deg, $baseclr 15%, rgba(255, 255, 255, 0) 110%) border-box !important;
                }
            }

            &::before {
                background: linear-gradient(252.84deg, #FFFFFF 20%, rgba(255, 255, 255, 0) 90%) border-box !important;
            }

            img {
                position: absolute;
                transition: 300ms ease-in-out;
                z-index: -1;
            }

            h3 {
                font-size: 3.6rem;
                font-weight: 300;
                line-height: 3.8rem;
                color: rgba($white, .9);
                text-transform: capitalize;

                @media (max-width: 1799px) {
                    font-size: 3.2rem;
                    line-height: 1.3;
                }

                @media (max-width: 1599px) {
                    font-size: 2.8rem;
                }

                @media (max-width: 1399px) {
                    font-size: 2.4rem;
                }

                @media (max-width: 991px) {
                    font-size: 2rem;
                }

                @media (max-width: 767px) {
                    font-size: 1.8rem;
                }
            }

            p {
                font-family: $poppinsfont;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 2.4rem;
                color: rgba($whiteE8, 0.9);
                margin-top: 1.6rem;

                @media (max-width: 1599px) {
                    font-size: 1.4rem;
                    line-height: 1.4;
                }

                @media (max-width: 1399px) {
                    font-size: 1.2rem;
                    margin-top: 1rem;
                }
            }

            &.earn {
                padding: 25.7rem 4rem 10.7rem;

                @media (max-width: 1799px) {
                    padding: 20rem 4rem 10rem;
                }

                @media (max-width: 1599px) {
                    padding: 16rem 2.8rem 8rem;
                }

                @media (max-width: 1399px) {
                    padding: 14rem 2rem 6rem;

                    h3 {
                        max-width: 30rem;
                    }
                }

                @media (max-width: 399px) {
                    padding: 14rem 2rem 2rem;
                }

                img {
                    top: 0.1rem;
                    right: 0.1rem;

                    @media (max-width: 1799px) {
                        width: 22rem;
                    }

                    @media (max-width: 1599px) {
                        width: 18rem;
                    }

                    @media (max-width: 1399px) {
                        width: 16rem;
                    }
                }

                p {
                    max-width: 46.2rem;
                }

                &:hover {
                    >img {
                        transform: translateX(1rem) translateY(-1rem);
                    }
                }
            }

            &.monthly {
                display: flex;
                flex-direction: column;
                text-align: center;
                padding: 2rem 2rem 7.4rem;

                @media (max-width: 1599px) {
                    padding: 2rem 2.8rem 6rem;
                }

                @media (max-width: 1399px) {
                    padding: 2rem 2rem 4rem;
                }

                @media (max-width: 665px) {
                    padding: 14rem 2rem 6rem;
                }

                @media (max-width: 499px) {
                    padding: 12rem 2rem 6rem;
                }

                @media (max-width: 419px) {
                    padding: 12rem 1rem 6rem;
                }

                @media (max-width: 399px) {
                    padding: 8rem 2rem 2rem;
                }


                img {
                    top: 0;
                    right: 0.482rem;

                    @media (max-width: 1799px) {
                        width: 24rem;
                    }

                    @media (max-width: 1599px) {
                        width: 20rem;
                    }

                    @media (max-width: 1399px) {
                        width: 16rem;
                        right: 2rem;
                    }

                    @media (max-width: 499px) {
                        width: 12rem;
                    }

                    @media (max-width: 419px) {
                        width: 10rem;
                    }
                }

                h3 {
                    margin: auto auto 0;

                    @media (max-width: 1399px) {
                        margin: auto auto 0;
                    }
                }

                p {
                    max-width: 27.7rem;
                    margin-inline: auto;
                }

                &:hover {
                    >img {
                        transform: translateX(1rem) translateY(-1rem);
                    }
                }
            }

            &.down_payment {
                padding: 8.6rem 4.4rem 2rem;

                @media (max-width: 1599px) {
                    padding: 6rem 2.8rem 2rem;
                }

                @media (max-width: 1399px) {
                    padding: 4rem 2rem 2rem;
                }

                @media (max-width: 499px) {
                    padding: 2rem;
                }

                @media (max-width: 419px) {
                    padding: 1rem;
                    text-align: center;
                }

                @media (max-width: 399px) {
                    padding: 2rem 2rem 10rem;
                }

                img {
                    bottom: 0.1rem;
                    right: 0.5rem;

                    @media (max-width: 1799px) {
                        width: 26rem;
                    }

                    @media (max-width: 1599px) {
                        width: 20rem;
                    }

                    @media (max-width: 1399px) {
                        width: 18rem;
                    }

                    @media (max-width: 499px) {
                        width: 14rem;
                    }

                    @media (max-width: 419px) {
                        width: 10rem;
                    }
                }

                p {
                    max-width: 34.4rem;
                }

                &:hover {
                    >img {
                        transform: translateY(1rem);
                    }
                }
            }

            &.control {

                h3 {
                    max-width: 40.2rem;
                    margin-bottom: 1.8rem;
                }
            }

            &.appreciation {
                @media (max-width: 665px) {
                    img {
                        width: 68%;
                    }
                }

                p {}
            }

            &.control,
            &.appreciation {
                padding: 4.4rem;

                @media (max-width: 1599px) {
                    padding: 4rem 2.8rem;
                }

                @media (max-width: 1399px) {
                    padding: 3.4rem 2rem;
                }

                @media (max-width: 665px) {
                    padding: 3.4rem 2rem 10rem;
                }

                img {
                    bottom: 0;
                    left: 0;
                }

                &:hover {
                    >img {
                        transform: translateY(1rem);
                    }
                }
            }

            &.diverse {
                background-color: rgba($black, 0.3);
                backdrop-filter: blur(0.8rem);
                padding: 29rem 3.1rem 8.9rem;

                @media (max-width: 1799px) {
                    padding: 20rem 3.1rem 7rem;
                }

                @media (max-width: 1599px) {
                    padding: 17.68rem 2.8rem 6rem;
                }

                @media (max-width: 1399px) {
                    padding: 14.6rem 2rem 4rem;
                }

                @media (max-width: 399px) {
                    padding: 14rem 2rem 2rem;
                }

                img {
                    top: 0.1rem;
                    right: 0.1rem;
                    border-radius: inherit;

                    @media (max-width: 1799px) {
                        width: 23rem;
                    }

                    @media (max-width: 1599px) {
                        width: 20rem;
                    }

                    @media (max-width: 1399px) {
                        width: 16rem;
                    }
                }

                h3 {
                    max-width: 34.3rem;
                }

                p {
                    max-width: 34.2rem;
                }

                &:hover {
                    >img {
                        transform: translateY(-1rem) translateX(1rem);
                    }
                }
            }
        }
    }
}