@import "../../../../assets/theme/_var.scss";

.invest_sec {
    position: relative;
    z-index: 2;

    // overflow-x: clip;
    .container {
        @media (max-width: 991px) {
            padding: 0 15rem;
        }

        @media (max-width: 767px) {
            padding: 0 10rem;
        }

        @media (max-width: 575px) {
            padding: 0 2rem;
        }
    }

    &::before {
        @extend %shadow;
        width: 161rem;
        height: 272rem;
        left: 0;
        top: 0;
        transform: translateY(-50%);
        background: url($s3 + "s3___invesLeftShadow.png") right center/cover no-repeat;

        @media (max-width: 1199px) {
            content: unset;
        }
    }

    .heading {
        margin: 0 auto 6.5rem;
        max-width: 79.5rem;
        text-align: center;

        @media (max-width: 1399px) {
            margin: 0 auto 4rem;
        }

        p {
            margin-top: 2.7rem;

            @media (max-width: 1399px) {
                margin-top: 2rem;
            }
        }
    }

    .invest_img {
        max-width: 89.1rem;
        position: relative;
        margin: 0 auto;

        @media (max-width: 1399px) {
            max-width: 76rem;
        }

        @media (max-width: 1199px) {
            max-width: 68rem;
        }

        @media (max-width: 991px) {
            max-width: 100%;
        }

        @media (max-width: 575px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .invest_card {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem;
            @extend %border_gradient;
            position: absolute;
            width: 24rem;
            height: 24rem;

            &:hover {
                h3 {
                    transform: translateY(-1rem);
                }

                &::before {
                    background: linear-gradient(222.84deg, $baseclr 15%, rgba(255, 255, 255, 0) 110%) border-box;
                }
            }

            @media (max-width: 1399px) {
                width: 20rem;
                height: 20rem;
            }

            @media (max-width: 1199px) {
                width: 16rem;
                height: 16rem;
                padding: 1.8rem;
            }

            @media (max-width: 991px) {
                width: 12rem;
                height: 12rem;
                border-radius: 1.4rem;
                padding: 1rem;
            }

            @media (max-width: 575px) {
                position: static;
                transform: none !important;
                width: calc((100% - 2rem) / 3);
                height: auto;
                min-height: 12rem;

                @media (max-width: 375px) {
                    width: calc((100% - 2rem) / 2);
                }
            }

            &.forecast {
                width: 23.1rem;
                height: 23.1rem;
                right: -9.4rem;
                bottom: -3.2rem;
                background: linear-gradient(131.72deg, rgba(0, 43, 24, 0.44) 30.58%, rgba(3, 172, 98, 0.44) 95.82%);

                @media (max-width: 1399px) {
                    width: 18rem;
                    height: 18rem;
                }

                @media (max-width: 1199px) {
                    width: 14rem;
                    height: 14rem;
                }

                @media (max-width: 991px) {
                    width: 10rem;
                    height: 10rem;
                    right: -4rem;
                    bottom: -2rem;
                }

                @media (max-width: 575px) {
                    width: calc((100% - 2rem) / 3);
                    height: auto;
                }

                @media (max-width: 375px) {
                    width: 100%;
                    margin-top: 2rem;
                }
            }

            &.quick {
                left: 0;
                transform: translateX(calc(-100% + 3.8rem)) translateY(calc(-50% + 0.5rem));
                top: 50%;
                background: linear-gradient(229.84deg, rgba(0, 43, 24, 0.44) 35.53%, rgba(3, 172, 98, 0.44) 95.53%);
            }

            &.own {
                right: 0;
                transform: translateX(calc(100% - 5rem));
                top: 4.2rem;
                background: linear-gradient(48.69deg, rgba(0, 43, 24, 0.44) 13.28%, rgba(3, 172, 98, 0.44) 97.95%);

                @media (max-width: 767px) {
                    transform: translateX(calc(100% - 6rem));
                    top: 2rem;
                }
            }

            h3 {
                max-width: 16.2rem;
                text-align: center;
                margin: auto;
                font-size: 2rem;
                font-weight: 500;
                transition: 300ms ease-in-out;
                line-height: 2.7rem;

                @media (max-width: 1599px) {
                    font-size: 1.8rem;
                    line-height: 1.4;
                }

                @media (max-width: 1399px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.4rem;
                }
            }
        }

        img {
            width: 100%;

            @media (max-width: 575px) {
                margin-top: 2rem;
            }
        }
    }
}