@import "../../../assets/theme/_var.scss";

.over_loader {
    background-color: $black;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}