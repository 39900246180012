@import "../../../assets/theme/_var.scss";

.heading {
    h2 {
        font-size: 4.8rem;
        font-weight: 300;
        line-height: 6rem;

        @media (max-width: 1799px) {
            font-size: 4.4rem;
            line-height: 1.4;
        }

        @media (max-width: 1599px) {
            font-size: 4rem;
        }

        @media (max-width: 1399px) {
            font-size: 3.4rem;
        }

        @media (max-width: 1199px) {
            font-size: 3rem;
        }

        @media (max-width: 991px) {
            font-size: 2.4rem;
        }

        @media (max-width: 575px) {
            font-size: 2rem;
        }
    }

    p {
        // text-align: center;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.7rem;
        color: $whiteCA;

        @media (max-width: 1599px) {
            font-size: 1.6rem;
            line-height: 1.4;
        }

        @media (max-width: 1399px) {
            font-size: 1.4rem;
        }
    }
}