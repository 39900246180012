@import '../../../../assets/theme/_var.scss';

.invest_fraction {
    padding-bottom: 11.9rem;
    z-index: 2;
    position: relative;

    @media (max-width:1399px) {
        padding-bottom: 6rem;
    }

    @media (max-width:575px) {
        padding-bottom: 3rem;
    }

    .invest_fraction_in {
        .heading {
            margin: 0 auto 6.5rem;
            max-width: 110.2rem;
            text-align: center;

            @media (max-width: 1399px) {
                margin: 0 auto 4rem;
            }

            p {
                margin-top: 2.7rem;

                @media (max-width: 1399px) {
                    margin-top: 1.4rem;
                }
            }
        }

        .property_cards {
            @media (max-width: 991px) {
                padding-inline: 3rem;
            }
            @media (max-width: 575px) {
                padding-inline: 2rem;
            }
            .slick-arrow {
                width: 2.8rem;
                height: 2.8rem;
                border-radius: 50%;
                border: 0.2rem solid $baseclr;
                background: url("../../../../assets/icons/down-arrow.png") center/1.6rem no-repeat;
                transition: 300ms ease-in-out;

                &.slick-disabled {
                    border-color: rgba($white ,0.2);
                }

                &::before {
                    content: unset;
                }

                &.slick-prev {
                    rotate: 90deg;
                    left: -4rem;
                }

                &.slick-next {
                    rotate: -90deg;
                    right: -4rem;
                }
            }
        }
    }
}