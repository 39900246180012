@import "../../../../assets/theme/_var.scss";

.custom_dropdown {
    &.company_dropdown {
        width: 63.5rem;

        @media (min-width: 1600px) {
            padding: 3rem 3.8rem;
        }

        @media (max-width: 1399px) {
            width: 50rem;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        .dropdown_in {
            margin-right: -1rem;

            .dropdown_list_item {
                padding-right: 1rem;

                &:nth-child(even) {
                    width: 55%;
                }

                &:nth-child(odd) {
                    width: 45%;
                }

                @media (max-width: 767px) {

                    &:nth-child(even),
                    &:nth-child(odd) {
                        width: 100%;
                    }
                }

                >a {
                    align-items: center;

                    >img {
                        margin-right: 1.1rem;
                    }
                }
            }
        }
    }
}