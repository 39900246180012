@import "../../../assets/theme/_var.scss";

.header {
    padding: 1.814rem 0;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    transition: 200ms ease-in-out;
    will-change: backdrop-filter, background-color;

    .border_bottom {
        background-color: rgba($white , 0.2);
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        // left: 50%;
        // transform: translateX(-50%);
    }

    @media (max-width: 1599px) {
        padding: 1.31rem 0;
    }

    @media (max-width: 1399px) {
        padding: 1rem 0;
    }

    &.scrolled {
        background-color: rgba($black, 0.1);
        backdrop-filter: blur(1rem);
    }

    .header_in {
        display: flex;
        position: relative;
        align-items: center;

        .logo {
            // max-width: 17.8rem;

            @media (max-width: 1599px) {
                max-width: 14rem;
            }

            @media (max-width: 1399px) {
                max-width: 10rem;
            }
        }

        .header_content {
            margin-left: auto;
            display: flex;
            align-items: center;

            .header_nav {
                margin-right: 5.2rem;

                @media (max-width: 1399px) {
                    margin-right: 3rem;
                }

                @media (max-width: 767px) {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    max-width: 35rem;
                    background-color: $black;
                    height: 100vh;
                    padding: 2rem;
                    overflow: auto;
                    z-index: 12;
                    transition: 300ms ease-in-out;

                    &:not(.active) {
                        transform: translateX(-100%);
                    }

                    .nav_logo {
                        margin-bottom: 2rem;
                    }
                }

                @media (max-width: 575px) {
                    max-width: 26rem;
                    padding: 1.4rem;
                }

                ul {
                    display: flex;
                    align-items: center;

                    @media (max-width: 767px) {
                        display: block;
                    }

                    li {
                        margin-left: 3.6rem;

                        @media (max-width: 1399px) {
                            margin-left: 2rem;
                        }

                        @media (max-width: 767px) {
                            margin: 0 0 2rem;
                        }

                        a {
                            font-size: 1.6rem;
                            font-weight: 400;
                            line-height: 2.7rem;
                            transition: color 300ms ease-in-out;

                            @media (max-width: 1399px) {
                                font-size: 1.4rem;
                                line-height: 2.4rem;
                            }

                            @media (max-width: 767px) {
                                width: 100%;
                            }

                            &:hover {
                                color: $baseclr;
                            }

                            &.has_dropdown {
                                position: relative;

                                &::before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-left: 1.4rem solid transparent;
                                    border-right: 1.4rem solid transparent;
                                    border-bottom: 2rem solid $dropdownclr;
                                    position: absolute;
                                    top: calc(100% + 1.6rem);
                                    left: 50%;
                                    transform: translateX(-50%) translateY(2rem);
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: 300ms ease-in-out;
                                    will-change: transform, opacity, visibility;

                                    @media (max-width: 1599px) {
                                        top: calc(100% + 1.1rem);
                                        border-left-width: 1rem;
                                        border-right-width: 1rem;
                                        border-bottom-width: 1.4rem;
                                    }

                                    @media (max-width: 1399px) {
                                        top: calc(100% + 1rem);
                                    }

                                    @media (max-width: 767px) {
                                        content: unset;
                                    }
                                }
                            }
                        }

                        .accordion-item {
                            border: none;
                            box-shadow: none;
                            border-radius: 0;
                            background-color: transparent;

                            .accordion-button {
                                padding: 0;
                                color: $white;
                                background-color: transparent;
                                box-shadow: none;

                                &::after {
                                    content: unset;
                                }
                            }

                            &.has_dropdown {
                                @media (max-width: 767px) {
                                    .accordion-button {
                                        &::before {
                                            content: "";
                                            width: 2rem;
                                            height: 2rem;
                                            background: url("../../../assets/icons/down-arrow.png") center/contain no-repeat;
                                            position: absolute;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            right: 1rem;
                                            transition: 300ms ease-in-out;
                                        }

                                        &.collapsed {
                                            &::before {
                                                transform: translateY(-50%) rotate(180deg);
                                            }
                                        }
                                    }
                                }
                            }

                            @media (min-width: 768px) {
                                .collapse:not(.show) {
                                    display: block;
                                }
                            }

                            .accordion-body {
                                padding: 0;
                            }
                        }

                        &:hover {
                            a {
                                &.has_dropdown {
                                    &::before {
                                        opacity: 1;
                                        visibility: visible;
                                        transform: translateY(0%) translateX(-50%);
                                    }
                                }
                            }

                            .custom_dropdown {
                                transform: translateY(0%);
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            .action_btn {
                width: 13rem;
                font-size: 1.3rem;
                padding: 0;

                @media (max-width: 1399px) {
                    width: 10rem;
                    height: 3.8rem;
                }
            }

            .toggle_btn {
                margin-left: 0.8rem;

                span {
                    display: block;
                    background-color: $baseclr;
                    width: 2.8rem;
                    height: 0.3rem;
                    border-radius: 1rem;
                    transition: 300ms ease-in-out;
                    transform-origin: left;

                    &:not(:last-child) {
                        margin-bottom: 0.65rem;
                    }
                }

                &.active {
                    span {
                        &:first-child {
                            transform: rotate(45deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:last-child {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

.header_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(0.8rem);
    opacity: 0;
    z-index: 8;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}