@import "../../../../assets/theme/_var.scss";

.resources {
    position: relative;

    .custom_dropdown {
        left: 50%;
        transform: translateX(-50%) translateY(2rem);
        top: 6.3rem;

        @media (min-width: 1600px) {
            padding: 3.1rem 3.8rem;
        }

        @media (max-width: 1599px) {
            top: 5rem;
        }

        @media (max-width: 1399px) {
            top: 4.2rem;
        }

        @media (max-width: 767px) {
            transform: translateY(0%);
            opacity: 1;
            visibility: visible;
        }

        &.resources_dropdown {
            width: 38.2rem;

            @media (max-width: 767px) {
                width: 100%;
            }

            .dropdown_in {
                margin-right: -0rem;

                .dropdown_list_item {
                    padding-right: 0rem;

                    @media (min-width: 768px) {
                    &:nth-child(even) {
                        width: 40%;
                    }

                    &:nth-child(odd) {
                        width: 60%;
                    }
                        
                    }

                    >a {
                        align-items: center;

                        >img {
                            margin-right: 1.1rem;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        &:hover {
            .custom_dropdown {
                transform: translateX(-50%) !important;
            }
        }
    }
}