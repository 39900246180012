@import "../../../../assets/theme/_var.scss";

.hero_sec {
    background: url($s3 + "s3___hero.png") center/cover no-repeat;
    position: relative;
    // z-index: -4;
    padding-top: 18.3rem;

    @media (max-width: 1599px) {
        padding-top: 14rem;
    }

    @media (max-width: 1399px) {
        padding-top: 10rem;
    }

    @media (max-width: 575px) {
        padding-top: 8rem;
    }

    .container {
        max-width: 126.9rem;

        @media (max-width: 1599px) {
            max-width: 112rem;
        }

        @media (max-width: 1399px) {
            max-width: 100rem;
        }

        @media (max-width: 1199px) {
            max-width: 82rem;
        }
    }

    .hero_in {
        height: 94rem;
        // height: 100dvh;
        display: flex;
        flex-direction: column;

        @media (max-width: 1599px) {
            height: 80rem;
        }

        @media (max-width: 1399px) {
            height: 65rem;
        }

        @media (max-width: 1199px) {
            height: 55rem;
        }

        @media (max-width: 575px) {
            min-height: 85dvh;
        }

        h1 {
            font-size: 4rem;
            font-weight: 400;
            line-height: 5.4rem;
            letter-spacing: -0.1rem;
            text-align: center;
            max-width: 104.2rem;
            margin: 0 auto;

            @media (max-width: 1799px) {
                font-size: 3.8rem;
                line-height: 1.4;
                max-width: 100.2rem;
            }

            @media (max-width: 1599px) {
                font-size: 3.4rem;
                max-width: 90rem;
            }

            @media (max-width: 1399px) {
                font-size: 3rem;
                max-width: 75rem;
            }

            @media (max-width: 1199px) {
                font-size: 2.6rem;
                max-width: 65rem;
            }
        }

        p {
            font-size: 2rem;
            text-align: center;
            margin: 0.6rem 0 0;
            font-weight: 400;
            line-height: 2.7rem;
            color: $whiteCA;

            @media (max-width: 1599px) {
                font-size: 1.6rem;
                line-height: 1.4;
            }

            @media (max-width: 1399px) {
                font-size: 1.4rem;
            }
        }

        .hero_cards {
            display: flex;
            margin: auto -1.5rem 0;
            transform: translateY(-3rem);

            @media (max-width: 1599px) {
                transform: translateY(-1.5rem);
            }

            @media (max-width: 1399px) {
                margin: auto -0.75rem 0;
                transform: translateY(0);
            }

            @media (max-width: 479px) {
                margin: auto -0.25rem 0;
            }

            .card_col {
                width: calc(100% / 3);
                padding: 0 1.5rem;

                @media (max-width: 1399px) {
                    padding: 0 0.75rem;
                }

                @media (max-width: 479px) {
                    padding: 0 0.25rem;
                }

                .hero_card {
                    padding: 4.4rem 4.3rem 4.6rem;
                    text-align: center;
                    @extend %border_gradient;
                    height: 100%;

                    &:hover {
                        >img {
                            transform: translateY(-1rem);
                        }

                        &::before {
                            background: linear-gradient(222.84deg, $baseclr 15%, rgba(255, 255, 255, 0) 110%) border-box;
                        }

                        h3 {
                            color: $baseclr;
                        }
                    }

                    &::before {
                        background: linear-gradient(222.84deg, #FFFFFF 15%, rgba(255, 255, 255, 0) 110%) border-box;
                    }

                    @media (max-width: 1599px) {
                        padding: 3rem;
                    }

                    @media (max-width: 1399px) {
                        padding: 2.4rem;
                    }

                    @media (max-width: 1199px) {
                        padding: 2rem;
                    }

                    @media (max-width: 479px) {
                        border-radius: 1.4rem;
                        padding: 1.2rem;
                    }

                    >img {
                        width: 7rem;
                        height: 7rem;
                        transition: 300ms ease-in-out;

                        @media (max-width: 1599px) {
                            height: 6.4rem;
                            width: 6.4rem;
                        }

                        @media (max-width: 1399px) {
                            height: 5.4rem;
                            width: 5.4rem;
                        }

                        @media (max-width: 1199px) {
                            height: 4.4rem;
                            width: 4.4rem;
                        }

                        @media (max-width: 479px) {
                            width: 3.4rem;
                            height: 3.4rem;
                        }
                    }

                    h3 {
                        margin-top: 2.4rem;
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 1.2rem;
                        transition: 300ms ease-in-out;

                        @media (max-width: 1599px) {
                            font-size: 1.6rem;
                            line-height: 2.6rem;
                        }

                        @media (max-width: 1399px) {
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            margin-top: 1rem;
                        }

                        @media (max-width: 1199px) {
                            font-size: 1.3rem;
                            line-height: 2.2rem;
                            margin-top: 0.8rem;
                        }

                        @media (max-width: 479px) {
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}