@import "../../../../assets/theme/_var.scss";

.custom_dropdown {
    &.service_dropdown {
        @media (min-width: 1400px) {
            width: 110rem;
        }

        .dropdown_in {
            margin-right: -2rem;

            .dropdown_list_item {
                width: 33.333333333333333%;
                padding-right: 2rem;

                @media (max-width: 767px) {
                    width: 100%;
                }

                @media (max-width: 575px) {
                    padding-right: 0;
                }
            }
        }
    }
}