@import "../../../assets/theme/_var.scss";

.footer {
    padding-top: 13.8rem;
    width: 100%;
    margin: auto auto 0;
    position: relative;
    z-index: 1;
    max-width: 192rem;

    @media (max-width: 1799px) {
        padding-top: 10rem;
    }

    @media (max-width: 1399px) {
        padding-top: 6rem;
    }

    @media (max-width: 575px) {
        padding-top: 4rem;
    }

    &::before {
        @extend %shadow;
        width: 161.9rem;
        height: 198rem;
        bottom: 0;
        right: 0;
        background: url($s3 + "s3___footer-shadow.png") center/contain no-repeat;

        @media (max-width: 991px) {
            width: 100%;
            height: 100rem;
            background-position: bottom right;
        }
    }

    .footer_in {
        display: flex;
        align-items: flex-start;
        padding-bottom: 5.5rem;
        border-bottom: 1px solid rgba($white, 0.1);

        @media (max-width: 1799px) {
            padding-bottom: 3rem;
        }

        @media (max-width: 1399px) {
            padding-bottom: 2rem;
        }

        @media (max-width: 899px) {
            flex-wrap: wrap;
        }

        h3 {
            font-family: "Montserrat", sans-serif;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 2.7rem;
            color: $baseclr;
            margin-bottom: 1.8rem;

            @media (max-width: 1599px) {
                font-size: 1.6rem;
                line-height: 2.6rem;
            }

            @media (max-width: 1399px) {
                font-size: 1.4rem;
                line-height: 2.4rem;
                margin-bottom: 1rem;
            }

            @media (max-width: 1199px) {
                font-size: 1.3rem;
                line-height: 2.2rem;
                margin-bottom: 0.8rem;
            }
        }

        .footer_left {
            max-width: 51.1rem;

            @media (max-width: 1399px) {
                max-width: 40rem;
            }

            @media (max-width: 1199px) {
                max-width: 30rem;
            }

            @media (max-width: 899px) {
                max-width: 100%;
                width: 100%;
                text-align: center;
            }

            .logo {
                @media (max-width: 1599px) {
                    max-width: 14rem;
                }
            }

            p {
                margin: 2rem 0 1rem;
                font-size: 1.6rem;
                text-transform: capitalize;
                font-weight: 400;
                line-height: 2.8rem;
                color: $whiteCA;

                @media (max-width: 1599px) {
                    font-size: 1.4rem;
                    margin: 1rem 0;
                }

                @media (max-width: 1399px) {
                    font-size: 1.3rem;
                    margin: 0.8rem 0;
                }

                @media (max-width: 1199px) {
                    font-size: 1.2rem;
                    line-height: 2.4rem;
                }

                @media (max-width: 899px) {
                    max-width: 50rem;
                    margin: 0.8rem auto;
                }
            }

            .address {
                margin-bottom: 10rem;
                font-size: 1.6rem;
                line-height: 1.8;
                color: $whiteCA;

                @media (max-width: 1599px) {
                    margin-bottom: 5rem;
                }

                @media (max-width: 1399px) {
                    font-size: 1.3rem;
                    margin-bottom: 3rem;
                }

                @media (max-width: 1199px) {
                    margin-bottom: 2rem;
                }

                @media (max-width: 899px) {}
            }

            h3 {
                margin-bottom: 1.1rem;
            }

            ul {
                display: flex;
                align-items: center;

                @media (max-width: 899px) {
                    justify-content: center;
                }

                li {
                    &:not(:last-child) {
                        margin-right: 2.5rem;

                        @media (max-width: 1599px) {
                            margin-right: 2rem;
                        }

                        @media (max-width: 1199px) {
                            margin-right: 1.4rem;
                        }
                    }
                }
            }
        }

        .footer_right {
            margin-top: 6.4rem;
            margin-left: auto;
            padding-right: 8.8rem;
            display: flex;
            align-items: flex-start;

            @media (max-width: 1679px) {
                padding-right: 0;
            }

            @media (max-width: 1599px) {
                margin-top: 5.6rem;
            }

            @media (max-width: 1399px) {
                // padding-right: 4rem;
                margin-top: 5rem;
            }

            @media (max-width: 991px) {
                padding-right: 0;
            }

            @media (max-width: 899px) {
                margin: 2rem auto 0;
                width: 100%;
                max-width: 50rem;
                justify-content: space-between;
            }

            .footer_nav {
                margin-left: 12.4rem;

                @media (max-width: 1679px) {
                    margin-left: 8rem;
                }

                @media (max-width: 1399px) {
                    margin-left: 8rem;
                }

                @media (max-width: 991px) {
                    margin-left: 4rem;
                }

                @media (max-width: 899px) {
                    margin-left: 2rem;

                    &:first-child {
                        margin: 0;
                    }
                }

                &:last-child {
                    li a {
                        white-space: nowrap;
                    }
                }

                @media (min-width: 1400px) {
                    &:last-child {
                        margin-left: 5rem;
                    }
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    height: 25rem;
                    flex-wrap: wrap;
                    column-gap: 3rem;

                    @media (max-width: 1599px) {
                        height: 23rem;
                    }

                    @media (max-width: 1399px) {
                        height: 18rem;
                    }

                    @media (max-width: 1199px) {
                        height: 16rem;
                    }

                    @media (max-width: 479px) {
                        height: auto;
                    }

                    li {
                        &:not(:last-child) {
                            margin-bottom: 2rem;

                            @media (max-width: 1599px) {
                                margin-bottom: 1rem;
                            }

                            @media (max-width: 1199px) {
                                margin-bottom: 0.4rem;
                            }
                        }

                        a {
                            font-size: 1.6rem;
                            width: 100%;
                            font-weight: 400;
                            line-height: 3rem;
                            color: $whiteCA;
                            transition: 300ms ease-in-out;

                            @media (max-width: 1399px) {
                                font-size: 1.4rem;
                                line-height: 2.6rem;
                            }

                            @media (max-width: 1199px) {
                                font-size: 1.2rem;
                                line-height: 2.4rem;
                            }

                            @media (max-width: 479px) {
                                max-width: 12rem;
                                line-height: 1.4;
                            }

                            &:hover {
                                color: $baseclr;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer_bottom {
        padding: 3.5rem 0 8.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1799px) {
            padding: 2rem 0 3rem;
        }

        @media (max-width: 1399px) {
            padding: 2rem 0;
        }

        @media (max-width: 991px) {
            display: block;
            text-align: center;
        }

        p {
            font-family: "Montserrat", sans-serif;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.7rem;
            text-align: right;
            color: $whiteCA;

            @media (max-width: 1399px) {
                font-size: 1.3rem;
                line-height: 2.4rem;
            }

            @media (max-width: 1199px) {
                font-size: 1.2rem;
                line-height: 2.2rem;
            }

            @media (max-width: 991px) {
                text-align: center;
                margin-top: 1.4rem;
            }
        }

        ul {
            display: flex;
            align-items: center;

            @media (max-width: 991px) {
                justify-content: center;
            }

            @media (max-width: 449px) {
                flex-wrap: wrap;
            }

            li {
                &:not(:last-child) {
                    margin-right: 9.1rem;

                    @media (max-width: 1399px) {
                        margin-right: 4rem;
                    }

                    @media (max-width: 1199px) {
                        margin-right: 2rem;
                    }

                    @media (max-width: 991px) {
                        margin-right: 1.4rem;
                    }
                }

                a {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 2.7rem;
                    color: $whiteCA;
                    transition: 300ms ease-in-out;

                    @media (max-width: 1399px) {
                        font-size: 1.3rem;
                        line-height: 2.4rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 1.2rem;
                        line-height: 2.2rem;
                    }

                    &:hover {
                        color: $baseclr;
                    }
                }
            }
        }
    }
}