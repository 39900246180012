@import "./assets/theme/_var.scss";

html {
    font-size: 62.5%;
}

body {
    color: white;
    background-color: black;
    font-family: $basefont;

}

#root {
    overflow: clip;
}

img,
canvas,
iframe,
video,
svg {
    max-width: 100%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.p {
    margin-bottom: 0;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: inherit;
}

a,
span {
    display: inline-block;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.container {
    padding: 0 1.5rem;

    @media (min-width: 1400px) {
        max-width: 129rem;
    }

    @media (min-width: 1699px) {
        max-width: 162.6rem;
    }

    @media (max-width:991px) {
        max-width: 100%;
    }
}

::-webkit-scrollbar {
    display: none;
}

[data-no-transition] {
    transition: none !important;
}