@import "../../../../assets/theme/_var.scss";

.enterprise {
    padding: 8.8rem 0rem 8.8rem;
    position: relative;

    @media (max-width: 991px) {
        padding: 6rem 0;
    }

    &::before {
        content: "";
        width: 175.4rem;
        height: 272.9rem;
        background: url($s3 + "s3___enterprise-left-shadows.png") center/contain no-repeat;
        position: absolute;
        top: -62rem;
        left: 0;
        z-index: 0;
        pointer-events: none;

        @media (max-width: 1599px) {
            width: 100rem;
            height: 200rem;
            top: -56rem;
        }
    }

    .enterprise_box {
        @extend %border_gradient;
        background-color: rgba($black, 0.3);
        padding: 7rem 7rem 6.2rem;

        @media (max-width: 1799px) {
            padding: 6rem;
        }

        @media (max-width: 1599px) {
            padding: 4rem;
        }

        @media (max-width: 1399px) {
            padding: 3rem;
        }

        @media (max-width: 1199px) {
            padding: 2.8rem;
        }

        @media (max-width: 575px) {
            padding: 1.8rem;
        }

        .heading {
            max-width: 80rem;
            text-align: center;
            margin: 0 auto 6rem;

            @media (max-width: 1799px) {
                max-width: 60rem;
                margin: 0 auto 4rem;
            }

            @media (max-width: 1399px) {
                margin: 0 auto 2rem;
            }
        }

        .enterprise_cards {
            display: flex;

            @media (max-width: 575px) {
                flex-wrap: wrap;
            }

            .enterprise_card {
                width: 100%;
                @extend %border_gradient;
                text-align: center;
                padding: 4.7rem;

                >* {
                    transition: 300ms ease-in-out;
                }

                &:hover {
                    >* {
                        transform: translateY(-1rem);
                    }

                    &::before {
                        background: linear-gradient(222.84deg, $baseclr 15%, rgba(255, 255, 255, 0) 110%) border-box;
                    }
                }

                @media (max-width: 1799px) {
                    padding: 4rem;
                }

                @media (max-width: 1599px) {
                    padding: 3rem;
                }

                @media (max-width: 1399px) {
                    padding: 2.4rem;
                }

                @media (max-width: 767px) {
                    padding: 1.8rem;
                }

                h3 {
                    max-width: 23.4rem;
                    margin-inline: auto;
                    font-size: 2.3rem;
                    font-weight: 700;
                    line-height: 2.8rem;
                    color: $secondaryclr;

                    @media (max-width: 1799px) {
                        font-size: 2.2rem;
                        line-height: 1.4;
                        min-height: 6.2rem;
                    }

                    @media (max-width: 1599px) {
                        font-size: 2rem;
                        min-height: 5.6rem;
                    }

                    @media (max-width: 1399px) {
                        font-size: 1.8rem;
                        min-height: 5rem;
                    }

                    @media (max-width: 1283px) {
                        font-size: 1.6rem;
                        min-height: 4.5rem;
                    }

                    @media (max-width: 575px) {
                        min-height: auto;
                    }
                }

                p {
                    max-width: 35.4rem;
                    margin: 2rem auto 0;
                    font-size: 1.6rem;
                    font-weight: 500;
                    line-height: 2.6rem;

                    @media (max-width: 1399px) {
                        font-size: 1.4rem;
                        line-height: 1.4;
                        margin: 2rem auto 0;
                    }

                    @media (max-width: 1283px) {
                        font-size: 1.2rem;
                        margin: 1.6rem auto 0;
                    }

                    @media (max-width: 575px) {
                        margin: 1rem auto 0;
                    }
                }

                &:not(:last-child) {
                    margin-right: 3.6rem;

                    @media (max-width: 1799px) {
                        margin-right: 3rem;
                    }

                    @media (max-width: 1599px) {
                        margin-right: 2rem;
                    }

                    @media (max-width: 1399px) {
                        margin-right: 1.4rem;
                    }

                    @media (max-width: 575px) {
                        margin: 0 0 1rem;
                    }
                }
            }
        }
    }
}