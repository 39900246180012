@import "../../../assets/theme/_var.scss";

.custom_btn {
    font-family: $poppinsfont;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $baseclr2;
    // color: $black24;
    color: $white;
    padding: 0 2.4rem;
    height: 4.5rem;
    border-radius: 1rem;
    border: 2px solid transparent;
    transition: 300ms ease-in-out;

    @media (max-width: 1399px) {
        font-size: 1.4rem;
        height: 4rem;
    }

    &:disabled {
        background-color: $black24;
        color: rgba($white, 0.5);
    }

    &:enabled {
        &:hover {
            color: $baseclr;
            border-color: $baseclr;
            background-color: transparent;
        }
    }
}