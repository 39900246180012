@import "../../../../assets/theme/_var.scss";

.explore_sec {
    padding: 7.7rem 0 9rem;
    position: relative;
    z-index: 1;
    // overflow-x: clip;

    @media (max-width: 1199px) {
        padding: 6rem 0;
    }

    &::before {
        @extend %shadow;
        width: 182rem;
        height: 273rem;
        right: 0;
        top: 100%;
        transform: translateY(-50%);
        background: url($s3 + "s3___wealthRight.png") center/cover no-repeat;
    }

    .heading {
        margin-bottom: 7rem;
        text-align: center;

        @media (max-width: 1399px) {
            margin-bottom: 4rem;
        }

        p {
            margin-top: 1.7rem;
        }
    }

    .explore_cards {
        margin: -1.5rem;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 1399px) {
            margin: -0.75rem;
        }

        @media (max-width: 991px) {
            justify-content: center;
        }

        >div {
            width: calc(100% / 5);
            padding: 1.5rem;

            @media (max-width: 1399px) {
                padding: 0.75rem;
            }

            @media (max-width: 991px) {
                width: calc(100% / 4);
            }

            @media (max-width: 767px) {
                width: calc(100% / 3);
            }

            @media (max-width: 499px) {
                width: 50%;
            }

            .explore_card {
                @extend %border_gradient;
                // background-color: $black;
                padding: 3.5rem 3rem;
                display: flex;
                align-items: center;
                height: 100%;

                &:hover {

                    >img,
                    h3 {
                        transform: translateY(-1rem);
                    }

                    &::before {
                        background: linear-gradient(222.84deg, $baseclr 15%, rgba(255, 255, 255, 0) 110%) border-box;
                    }

                    h3 {
                        color: $baseclr;
                    }
                }

                @media (max-width: 1799px) {
                    padding: 3rem 2.8rem;
                }

                @media (max-width: 1599px) {
                    padding: 2.4rem;
                }

                @media (max-width: 1399px) {
                    padding: 1.8rem;
                }

                @media (max-width: 1199px) {
                    min-height: 10rem;
                }

                @media (max-width: 991px) {
                    padding: 1rem;
                    border-radius: 1.4rem;
                    justify-content: center;
                }

                @media (max-width: 399px) {
                    flex-direction: column;
                    text-align: center;
                }

                img {
                    width: 5.9rem;
                    height: 5.9rem;
                    flex-shrink: 0;
                    margin-right: 2rem;
                    transition: 300ms ease-in-out;

                    @media (max-width: 1599px) {
                        width: 4.8rem;
                        height: 4.8rem;
                        margin-right: 1.4rem;
                    }

                    @media (max-width: 1399px) {
                        width: 4.4rem;
                        height: 4.4rem;
                        margin-right: 1rem;
                    }

                    @media (max-width: 1199px) {
                        width: 4rem;
                        height: 4rem;
                    }

                    @media (max-width: 399px) {
                        margin: 0 0 1rem;
                    }
                }

                h3 {
                    font-size: 2rem;
                    font-weight: 400;
                    line-height: 2.7rem;
                    transition: 300ms ease-in-out;

                    @media (max-width: 1599px) {
                        font-size: 1.8rem;
                        line-height: 1.4;
                    }

                    @media (max-width: 1399px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}