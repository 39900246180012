@import "../../../../assets/theme/_var.scss";

.building_sec {
    position: relative;
    z-index: 1;
    // overflow-x: clip;

    &::after {
        @extend %shadow;
    }

    &::after {
        width: 177rem;
        height: 170rem;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url($s3 + "s3___buildingShodowLeft.png") center left/contain no-repeat;
    }

    .building_in {
        background: url($s3 + "s3___building-bg.png") right/cover no-repeat;
        padding: 5rem 6rem;
        border-radius: 2rem;
        background-color: #00BE6B;

        @media (max-width: 767px) {
            background: linear-gradient(180deg, #00000088, #00000015), url($s3 + "s3___building-bg.png") right/cover no-repeat;
            padding: 5rem 2rem;
        }

        .heading {
            max-width: 58.5rem;

            @media (max-width: 767px) {
                text-align: center;
            }

            h2 {
                font-family: $poppinsfont;
                font-size: 4.6rem;
                font-weight: 500;
                line-height: 4.6rem;
                letter-spacing: -0.04em;

                @media (max-width: 1799px) {
                    font-size: 4.4rem;
                    line-height: 1.4;
                }

                @media (max-width: 1599px) {
                    font-size: 4rem;
                }

                @media (max-width: 1399px) {
                    font-size: 3.4rem;
                }

                @media (max-width: 1199px) {
                    font-size: 3rem;
                }

                @media (max-width: 991px) {
                    font-size: 2.4rem;
                }

                @media (max-width: 575px) {
                    font-size: 2rem;
                }
            }

            p {
                font-family: $poppinsfont;
                color: $white;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 1.3rem;
                margin: 1.8rem 0 4rem;

                @media (max-width: 1599px) {
                    font-size: 1.6rem;
                    line-height: 1.4;
                }

                @media (max-width: 1399px) {
                    font-size: 1.4rem;
                }
            }

        }

        .building_action {
            display: flex;

            @media (max-width: 767px) {
                justify-content: center;
            }

            @media (max-width: 375px) {
                flex-wrap: wrap;
            }

            button {
                height: 5.9rem;
                width: 19.1rem;

                @media (max-width: 1399px) {
                    height: 5rem;
                }

                @media (max-width: 767px) {
                    height: 4.6rem;
                    width: 16rem;
                    padding: 0;
                }

                &:not(:last-child) {
                    margin-right: 2rem;

                    @media (max-width: 375px) {
                        margin: 0 0 1rem;
                    }
                }

                &.light_btn {
                    background: $white;
                    color: $black24;
                }

                &.secondary_btn {
                    border-color: $white;
                    color: $white;
                    background-color: transparent;
                }
            }
        }
    }
}